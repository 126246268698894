<template>
  <div class="blindAccountView">
    <van-nav-bar :title="$t('绑定账号')" left-arrow @click-left="onClickLeft" />
    <van-tabs
      v-model="active"
      line-width="60px"
      background="#151d31"
      title-inactive-color="#ffffff"
      title-active-color="#4087f1"
    >
      <van-tab title="Line" name="0"></van-tab>
    </van-tabs>
    <div class="content_box text-center">
      <van-cell-group>
        <van-field
          v-model="tiktok"
          :label="$t('账号')"
          :disabled="disabled"
          :placeholder="$t('请输入WhatsApp账号')"
        />
      </van-cell-group>
      <van-button type="primary" class="big_other_btn2" @click="submitHandler">{{ $t('提交') }}</van-button>
    </div>
  </div>
</template>
<script>
import { saveToLocal, loadFromLocal } from "@/utils/local-storage";
import { setAccount } from "@/api/users";
import area from "@/utils/area";
export default {
  name: "login",
  data() {
    return {
      disabled: this.$store.getters.userinfo.tiktok ? true : false,
      tiktok: this.$store.getters.userinfo.tiktok,
      active: 0
    };
  },
  created() {
    this.areaList = area;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    submitHandler(e) {
      if (this.disabled == true) {
        this.$toast({
          message: this.$t("已设置"),
          duration: 1000
        });
        return;
      }
      const { tiktok } = this;
      let field = { tiktok };
      setAccount(field)
        .then(response => {
          this.$toast({
            message: response.msg,
            duration: 500,
            forbidClick: true
          });
          this.disabled = true;
        })
        .catch(error => {});
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.blindAccountView
  .van-tabs__line
    background-color: #4087f1
  .van-tab__text
    font-size: 0.42rem
    font-weight: bold
  .content_box
    margin: 0.3rem
    .mobile
      .van-cell
        padding: 0.26667rem 0.2rem 0.26667rem 0.42667rem
      .verify
        margin-top: 0.28rem
    h2
      color: $grayTextColor
    .van-cell
      background-color: $cellBackColor
      padding: 0.1rem 0.3rem
    .van-icon::before
      color: $grayTextColor
    .van-icon
      transform: rotate(90deg)
    .big_btn
      margin: 0.36rem 0 0 0
</style>
